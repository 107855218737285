@import './font/nunito.css';

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$panel-heading-padding: 0.25em 0.5em;
$footer-padding: 3rem;

// Import the rest of Bulma
@import "bulma";
@import '@creativebulma/bulma-tooltip';

.navbar-item.is-active{font-weight: bold;};

.button.has-text-left { justify-content: flex-start; };

.button.has-text-right { justify-content: flex-end; };

// Sticky table headers
table.tree-table {
  border-collapse: separate;
  th {
    position: sticky;
    background-color: white;
    top: 0;
    z-index: 10; }

  span[data-tooltip] {
    border: none; }

  .is-active {
    background-color: $dark;
    color: white; } }

// Sticky footer
.page-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

.content-wrapper {
  flex: 1; }

.navbar {
  // Align navbar with body content (looks better to me)
  padding: 0 0.75rem; }

// Clickable heading anchors
.heading-anchor {
  a {
    float: left;
    margin-left: -0.7em; }

  &:hover {
    svg {
      visibility: visible; } }

  svg {
    visibility: hidden;
    width: 0.55em !important; } }

.inspector {
  position: sticky;
  top: 10px;
  width: 410px;

  .panel-block {
    padding: 0 0 0 1em;
    div {
      overflow-y: auto;
      max-height: calc(100vh - 100px); } }

  .panel-heading {
    background-color: $dark;
    color: white; }

  table {
    table-layout: fixed;

    td {
      position: relative; }

    td span[data-tooltip] {
      border-bottom: none;
      cursor: auto !important, }

    td span.star {
      cursor: pointer;
      svg {
        color: $yellow; }
      &:hover svg {
        color: $grey; } }

    td:nth-child(1) {
      width: 210px; }
    td:nth-child(2) {
      width: 175px;
      word-wrap: break-word; } }

  .delete {
    float: right;
    margin-top: 3px; } }

.has-arrows {
  &:hover {
    .arrows {
      background-color: white;
      svg {
        visibility: visible; } } }

  .arrows {
    padding: 0 5px 0 5px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center; }

  svg {
    cursor: pointer;
    margin: 0 5px 0 0;
    visibility: hidden; } }

.flamegraph {
  display: inline-block;
  margin-bottom: 20px;
  float: left;
  width: 100%;

  .is-active > .rect {
    background-color: $dark;
    color: white; }

  .group {
    float: left;
    box-sizing: border-box; }

  .group:not(:last-child) {
    border-right: 1px solid white; }

  .rect {
    background-color: red;
    margin-bottom: 1px;
    height: 24px;
    color: white;

    span {
      width: 100%;
      padding: 0 5px;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } } }

.visualizer {
  .tabs > ul {
    flex-grow: unset; }
  .tabs > .stats {
    flex-grow: 1;
    padding: 0.5em 1em;
    border: 1px solid transparent;

    span {
      margin-right: 0.25em; } }

  .tabs .is-disabled a {
    color: $grey;

    cursor: not-allowed;

    &:hover {
      background-color: white;
      border-color: $tabs-toggle-link-border-color;
      z-index: auto; } } }

.texteditor {
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 12px;
  min-height: 320px;
  border: solid $border 1px;
  border-radius: $radius; }
